.wrapper-class {
  padding: 1rem;
  border: 1px solid #000;
}
.editor-class {
  background-color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  border: 1px solid #ccc;
  min-height: 150px;
  max-height: 150px;
}
.editor-class:focus {
  border: blue;
}

.toolbar-class {
  border: 1px solid #ccc;
}
